import React from 'react';
import { Link } from 'gatsby';

import Layout from '@common/Layout';
import { Container } from '@components/global';

const Thanks = () => (
  <Layout>
    <Container>
      <h1>Thanks for your message</h1>
      <p><br/>WAX will get back to you soon</p>
        <br/><br/>
      <Link to="/">Go back to the homepage</Link>
    </Container>
  </Layout>
);

export default Thanks;
